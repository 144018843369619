import React from 'react';
import SEO from '../components/App/SEO';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import {graphql} from 'gatsby';
import iPhoneImg from '../assets/images/blue-chat/iphone.webp'
import iphoneReversedImg from '../assets/images/blue-chat/iphoneReversed.webp'
import powerFullImg from '../assets/images/blue-chat/powerfull.webp'
import secureImg from '../assets/images/blue-chat/secure.webp'
import crossPlatformImg from '../assets/images/blue-chat/cross-platf.webp'
import messagingImg from '../assets/images/blue-chat/messages.webp'
import teamsImg from '../assets/images/blue-chat/teams.webp'
import indicatorsImg from '../assets/images/blue-chat/indicators.webp'

const BlueChat = ({data}) => {

  return (

    <Layout env={data.site.siteMetadata.env}>
      <Navbar />
      <div className="blue-chat-container bg-nav">
        <SEO
          postTitle="Download App | BlueChat"
          postDescription="Bluemail BlueChat"
          postImage="/img/bluechat/iOS-2.png"
          postURL="bluechat"
          postSEO
        />
        <div className="container content">
          <div className='row'>
            <div className='col bkg'>
              <div className='row'>
                <div className='col-lg-9 col-md-9 col-xs-12 d-flex align-items-center'>
                  <div className='row'>
                    <div className='col ml-5'>
                      <h1>BlueChat App <br /> built for your <span>productivity</span></h1>
                        <h4 className="mt-3" style={{color: "#B0B0B0", fontWeight: "400"}}>Instantly connect with the people at work, 
                          school and your personal contacts using any number of email account addresses.</h4>
                        <div style={{display: 'flex'}} className="mt-5">
                          <div className="row">
                              <div className="col-6">
                                <a href="https://play.google.com/store/apps/details?id=me.bluemail.ins">
                                    <img
                                    src={`/img/BlueMail_PlayStore.svg`}
                                    alt="bluechat play store"
                                    style={{ width: '190px' }}
                                    />
                                </a>
                              </div>
                              <div className="col-6">
                                <a href="https://apps.apple.com/us/app/blue-chat-messenger/id1552804418">
                                <img
                                    src={`/img/BlueMail_AppStore.svg`}
                                    alt="bluechat app store"
                                    style={{ width: '190px' }}
                                    />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-xs-12 phone'>                                 
                      <img src={iPhoneImg} alt="phone" />
                    </div>
                </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className='col-lg-6 col-md-6 col-xs-12 p--5'>
            <h2><span>Free,</span> Fast And For All Your Devices</h2>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col p--5'>
              <div className='row'>
                <div className='col-lg-4 col-md-4 col-xs-12 mt-3'>
                  <img className='icon' src={powerFullImg} alt="power" />
                  <h5 className='mt-3 mb-3'>Powerful</h5>
                  <p style={{lineHeight: "28px"}}>Communicate better and faster within teams. With BlueChat, you can create your teams instantly.</p>
                </div>
                <div className='col-lg-4 col-md-4 col-xs-12 mt-3'>
                  <img className='icon' src={secureImg} alt="power" />
                  <h5 className='mt-3 mb-3'>Secure</h5>
                  <p style={{lineHeight: "28px"}}>End-to-end encryption (E2EE). BlueChat is fully secure and your messages are always encrypted.</p>
                </div>
                <div className='col-lg-4 col-md-4 col-xs-12 mt-3'>
                  <img className='icon' src={crossPlatformImg} alt="power" />
                  <h5 className='mt-3 mb-3'>Cross Platform</h5>
                  <p style={{lineHeight: "28px"}}>Use everywhere. BlueChat works across all devices.</p>
                </div>
              </div>            
            </div>          
          </div>
          <div className='row'>
            <div className='col bkg'>
              <div className='row'>                           
                <div className='col-lg-4 col-md-4 col-xs-12 phoneLeft'>
                  <img src={iphoneReversedImg} alt="phone" />
                </div>
                <div className='col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-xs-12 d-flex align-items-center'>
                  <div className='row'>
                    <div className='col ml-5'>
                      <h2>We take your privacy  <span>Seriously</span> and never give any third party access to your chats.</h2>
                      </div>
                    </div>
                </div>   
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className='col-lg-6 col-md-6 col-xs-12 p--5'>
              <p style={{color: "#B0B0B0", margin: "0"}}>EFFICIENT</p>
              <h2><span>Real-Time</span> Communication</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col p--5'>
              <div className='row'>
                <div className='col-lg-4 col-md-4 col-xs-12 mt-3'>
                  <img className='icon' src={messagingImg} alt="power" />
                  <h5 className='mt-3 mb-3'>1 : 1 Messaging</h5>
                  <p style={{lineHeight: "28px"}}>Use everywhere. BlueChat works across all devices.</p>
                </div>
                <div className='col-lg-4 col-md-4 col-xs-12 mt-3'>
                  <img className='icon' src={teamsImg} alt="power" />
                  <h5 className='mt-3 mb-3'>Teams</h5>
                  <p style={{lineHeight: "28px"}}>End-to-end encryption (E2EE). BlueChat is fully secure and your messages are always encrypted.</p>
                </div>
                <div className='col-lg-4 col-md-4 col-xs-12 mt-3'>
                  <img className='icon' src={indicatorsImg} alt="power" />
                  <h5 className='mt-3 mb-3'>Indicators</h5>
                  <p style={{lineHeight: "28px"}}>Communicate better and faster within teams. With BlueChat, you can create your teams instantly.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default BlueChat

export const query = graphql`
query BlueChatPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
